import { replaceWithStringTheNullInObject } from '../helpers/utils'
import { ShippingAddressApiResultInterface } from '../model/api-result/ShippingAddressApiResult'
import { ShippingAddressStoreInterface } from '../state-manager/ShippingAddress'

export const shippingStoreAddressAdapter = (address: ShippingAddressApiResultInterface): ShippingAddressStoreInterface => {
  const addressNormalized = replaceWithStringTheNullInObject(address)
  return {
    selected: addressNormalized.default,
    additionalInfo: addressNormalized.additionalInfo,
    area: addressNormalized.area,
    city: addressNormalized.city,
    country: addressNormalized.country,
    default: addressNormalized.default,
    firstName: addressNormalized.firstName,
    id: addressNormalized.id,
    isValid: addressNormalized.isValid,
    lastName: addressNormalized.lastName,
    number: addressNormalized.number,
    phone: addressNormalized.phone,
    street: addressNormalized.street,
    zipCode: addressNormalized.zipCode
  }
}
