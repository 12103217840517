import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

export interface RetailerUserInfoStoreInterface extends Instance<typeof RetailerUserInfoStore> {}
export interface RetailerUserInfoStoreInterfaceSnapshotIn extends SnapshotIn<typeof RetailerUserInfoStore> {}
export interface RetailerUserInfoStoreInterfaceSnapshotOut extends SnapshotOut<typeof RetailerUserInfoStore> {}

export const RetailerUserInfoStore = types
  .model('RetailerUserInfoStore', {
    authorizeBehaviorProfiling: types.boolean,
    authorizeMarketingCommunications: types.boolean,
    companyName: types.optional(types.maybeNull(types.string), null),
    email: types.optional(types.maybeNull(types.string), null),
    extraData: types.optional(types.maybeNull(types.string), null),
    fullName: types.optional(types.maybeNull(types.string), null),
    gdprProcessorPartnerAcceptDate: types.optional(types.maybeNull(types.string), null),
    gdprProcessorPhotosiAcceptDate: types.optional(types.maybeNull(types.string), null),
    hasPermissionBecomeRetailerUser: types.boolean,
    id: types.number,
    iplabsRetailerId: types.number,
    isActive: types.boolean,
    isStaff: types.boolean,
    isValidProfile: types.boolean,
    name: types.optional(types.maybeNull(types.string), null),
    permissions: types.optional(types.maybeNull(types.string), null),
    phoneNumber: types.optional(types.maybeNull(types.string), null),
    shopCode: types.optional(types.maybeNull(types.string), null),
    showDeliveryNotes: types.boolean,
    showInvoices: types.boolean,
    surname: types.optional(types.maybeNull(types.string), null),
    taxcode: types.optional(types.maybeNull(types.string), null),
    types: types.optional(types.maybeNull(types.array(types.string)), null)
  })
  .actions((self: any): any => ({}))

export default RetailerUserInfoStore
