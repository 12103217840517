import { getSnapshot } from 'mobx-state-tree'
import { getCookieAnalyticsData, getCookieMarketingData } from '../../app/helpers/utils'
import { strReplaceAll } from '../helpers/utils'
import { TagManagerServiceInterface } from '../model/TagManagerServiceInterface'
import { ProductStoreInterfaceSnapshotOut } from '../state-manager/ProductStore'
import { ShoppingCartStoreInterface } from '../state-manager/ShoppingCartStore'

export interface GTMEventServiceInterface {
  addProduct(productId: string): void
  removeProduct(productId: string): void
  appliedCoupon(code: string): void
  cartInteraction(action: cartInteractionAction): void
  checkoutStep(step: number, actionFieldOption?: string): void
  checkoutDelivery(type: GTMEventDeliveryType, storeName?: string): void
  checkoutPaymentError(): void
  thankYouPageInteraction(cta: GTMThankYouPageCTA): void
  shareWithFriends(cta: GTMShareWithFriendsCTA): void
  virtualPageEvent(): void
  wSetup(language: string, pageType: string): void
  purchase(): void
}

export interface GTMEventProduct {
  name: string
  id: string
  price: number
  brand: string
  category: string
  variant: string
  quantity: number
}

export interface StaticGTMWSetupInterface {
  country: string
  language: string
  pageType: string
  currencyCode: string
  userID: string
}

export type cartInteractionAction = 'modify' | 'add_qty' | 'remove_qty' | 'delete'
export type GTMEventDeliveryType = 'home' | 'pick_up_store'
export type GTMThankYouPageCTA = 'print_other_emotions' | 'invite_your_friends'
export type GTMShareWithFriendsCTA = 'copy_code' | 'invite_your_friends'

/**
 * @class GTMEvent need to integrate the events reported in Webranking operative documents of Photosi
 */

export class AbstractGTMEvent {
  constructor(protected rootStore: ShoppingCartStoreInterface, protected tagmanager: TagManagerServiceInterface) {
    this.tagmanager = tagmanager
    this.rootStore = rootStore
  }

  static formatString(string: string = '') {
    const outString = strReplaceAll(string.trim(), ' ', '_').replace(/[^0-9a-zA-Z_-]/gi, '')
    return outString.toLowerCase()
  }

  protected formatString(string: string = '') {
    return GTMEvent.formatString(string)
  }

  private productAdapter(product: ProductStoreInterfaceSnapshotOut): GTMEventProduct {
    return {
      name: this.formatString(product.productCode),
      id: this.formatString(product.id),
      price: product.unitPrice || 0,
      brand: this.formatString(this.rootStore.productsHeader.distributor.configurationCode),
      category: this.formatString(product.description),
      variant: this.formatString(product.description),
      quantity: product.quantity || 0
    }
  }

  protected getAllProducts(): Array<GTMEventProduct> {
    const products: ProductStoreInterfaceSnapshotOut[] = getSnapshot(this.rootStore.products)
    return products.map((product: ProductStoreInterfaceSnapshotOut) => this.productAdapter(product))
  }

  protected getProduct(productId: string): GTMEventProduct[] {
    const products: GTMEventProduct[] = this.getAllProducts()
    //@ts-ignore
    return products.filter((item: GTMEventProduct) => {
      if (item.id === productId) {
        return item
      }
    })
  }

  protected push(value: object) {
    this.tagmanager.dataLayer.push(value)
  }

  protected changeProduct(eventLabel: string, productId: string): void {
    this.push({
      event: `${eventLabel}`,
      ecommerce: {
        currencyCode: this.formatString(this.rootStore.header.currencyCode),
        add: {
          products: this.getProduct(productId)
        }
      }
    })
  }
}

export class GTMEvent extends AbstractGTMEvent implements GTMEventServiceInterface {
  constructor(protected rootStore: ShoppingCartStoreInterface, protected tagmanager: TagManagerServiceInterface) {
    super(rootStore, tagmanager)
  }

  addProduct(productId: string): void {
    this.changeProduct('addToCart', productId)
  }

  removeProduct(productId: string): void {
    this.changeProduct('removeFromCart', productId)
  }

  appliedCoupon(code: string): void {
    this.push({
      event: 'GAevent',
      eventID: '25',
      eventCategory: 'coupon',
      eventAction: 'coupon_ok',
      eventLabel: this.formatString(code)
    })
  }

  cartInteraction(action: cartInteractionAction): void {
    this.push({
      event: 'GAevent',
      eventID: '26',
      eventCategory: 'cart_interaction',
      eventAction: action,
      eventLabel: 'cart_interaction'
    })
  }

  checkoutStep(step: number, actionFieldOption?: string): void {
    const stepData = {
      event: 'checkout',
      ecommerce: {
        currencyCode: this.formatString(this.rootStore.header.currencyCode),
        checkout: {
          actionField: {
            step: step
          },
          products: this.getAllProducts()
        }
      }
    }
    if (step == 3 && actionFieldOption) {
      Object.assign(stepData.ecommerce.checkout.actionField, { option: actionFieldOption })
    }
    if (step == 4 && actionFieldOption) {
      Object.assign(stepData.ecommerce.checkout.actionField, { option: actionFieldOption })
    }
    this.push(stepData)
  }

  checkoutDelivery(type: GTMEventDeliveryType, label?: string): void {
    this.push({
      event: 'GAevent',
      eventID: '28',
      eventCategory: 'checkout',
      eventAction: `delivery_${type}`,
      eventLabel: this.formatString(label || type)
    })
  }

  checkoutPaymentError(): void {
    this.push({
      event: 'GAevent',
      eventID: '32',
      eventCategory: 'checkout',
      eventAction: 'payment_error',
      eventLabel: this.rootStore.paymentMethod.code
    })
  }

  thankYouPageInteraction(cta: GTMThankYouPageCTA): void {
    this.push({ event: 'GAevent', eventID: '29', eventCategory: 'purchase_typ', eventAction: 'order_completed', eventLabel: cta })
  }

  shareWithFriends(cta: GTMShareWithFriendsCTA): void {
    this.push({
      event: 'GAevent',
      eventID: '30',
      eventCategory: 'purchase_typ',
      eventAction: 'invite_your_friends',
      eventLabel: cta
    })
  }

  static getVirtualPageEvent() {
    return { event: 'virtual_pageview' }
  }

  virtualPageEvent(): void {
    this.push(GTMEvent.getVirtualPageEvent())
  }

  static getWSetupOnbject({ language, country, pageType, currencyCode, userID }: StaticGTMWSetupInterface) {
    return {
      event: 'wSetup',
      country: GTMEvent.formatString(country),
      language: GTMEvent.formatString(language || ''),
      pageType: GTMEvent.formatString(pageType),
      pageCat: GTMEvent.formatString(pageType),
      pageId: GTMEvent.formatString(pageType),
      currencyCode: GTMEvent.formatString(currencyCode || ''),
      userLogged: userID ? 1 : 0,
      userID: userID || '',
      analyticsCookie: getCookieAnalyticsData(),
      marketingCookie: getCookieMarketingData()
    }
  }

  wSetup(language: string, pageType: string, currentCountry?: string): void {
    const country: string = currentCountry || language || ''
    const userID: string = this.rootStore.userInfo.id || ''
    const currencyCode: string = this.rootStore.header.currencyCode || ''
    const wSetup = GTMEvent.getWSetupOnbject({ language, country, pageType, currencyCode, userID })
    this.push(wSetup)
  }

  purchase(): void {
    this.push({
      event: 'purchase',
      ecommerce: {
        currencyCode: this.formatString(this.rootStore.header.currencyCode),
        purchase: {
          actionField: {
            id: this.rootStore.header.id,
            revenue: this.rootStore.header.vatIncludedSubTotal,
            tax: 0,
            shipping: this.rootStore.header.vatIncludedShippingCost,
            coupon: this.rootStore.header.shopCode,
            metric2: this.rootStore.header.vatIncludedTotal
          },
          products: this.getAllProducts()
        }
      }
    })
  }
}
