import { from, Observable, Observer, of } from 'rxjs'
import { catchError, map, retry } from 'rxjs/operators'
import { isDev } from '../helpers/utils'

const LEANPLUM_START_TIMEOUT = 1000
const LEANPLUM_START_RETRY = 2

const LEANPLUM_DEV = {
  appID: 'app_XQNH4mT2hDgqZwnr7ebNRcwtzl6Vl5W30ZQSOFVxtFg',
  production: 'prod_Afa4PWiUoD13EgWemZAyY0cSAHd6w2W3y3Eb0t0lxNQ',
  development: 'dev_RNT6bIB7EcxaYAB7DvrE4l2IrRMTSMY4Vmc4pauiJZA'
}

const LEANPLUM_PROD = {
  appID: 'app_uWwPdtUc9rQtkz5gujg9PjAndLo5MyypJFvusgBg6X0',
  production: 'prod_c6bw7GjuCNfxfzjTRnMeRG8aknqF0pExz8hW19o9iuM',
  development: 'dev_N7zDvOS8Jzh2rDZlFMnTD2TZhnbwlDeN442CapEP9yQ'
}

const leanplumService$ = new Observable((observer: Observer<any>) => {
  from(import('leanplum-sdk'))
    .pipe(map((emit: any) => emit.default))
    .subscribe(
      (leanplum: any) => {
        if (isDev()) {
          leanplum.setAppIdForDevelopmentMode(LEANPLUM_DEV.appID, LEANPLUM_DEV.development)
        } else {
          leanplum.setAppIdForProductionMode(LEANPLUM_PROD.appID, LEANPLUM_PROD.production)
        }

        setTimeout(() => {
          if (!observer.closed) {
            observer.error(new Error('Leanplum Timeout'))
          }
        }, LEANPLUM_START_TIMEOUT)

        leanplum.start((status: any) => {
          if (status === true) {
            if (isDev()) {
              console.log('Leanplum Success: ', status)
            }
            observer.next(leanplum)
            observer.complete()
          } else {
            if (isDev()) {
              console.error('Leanplum Fail')
            }
            observer.error('Leanplum Fail')
          }
        })
      },
      (err: Error) => {
        observer.error(err)
      }
    )
}).pipe(
  retry(LEANPLUM_START_RETRY),
  catchError((err: Error) => {
    console.error('Leanplum initialization failed')
    return of({ err })
  })
)

export default leanplumService$
