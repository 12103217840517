import { isDev, isIE } from '../helpers/utils'
import IImage from '../models/Image'
import { IPictureObj } from '../models/Picture'
import fallbackThumbnailIE11 from './../../assets/images/png/Icon_default_product.png'
import footer_100_1_lg from './../../assets/images/svg/footer_100_1_lg.svg'
import footer_100_1_xxl from './../../assets/images/svg/footer_100_1_xxl.svg'
import footer_100_2_lg from './../../assets/images/svg/footer_100_2_lg.svg'
import footer_100_2_xxl from './../../assets/images/svg/footer_100_2_xxl.svg'
import footer_100_3_lg from './../../assets/images/svg/footer_100_3_lg.svg'
import footer_100_3_xxl from './../../assets/images/svg/footer_100_3_xxl.svg'
import footerLogo from './../../assets/images/svg/footer_logo.svg'
import footer_pay from './../../assets/images/svg/footer_pay.svg'
import footer_pay_lg from './../../assets/images/svg/footer_pay_lg.svg'
import footer_pay_xxl from './../../assets/images/svg/footer_pay_xxl.svg'
import footer_shipping_types from './../../assets/images/svg/footer_shipping_types.svg'
import fallbackThumbnailSvg from './../../assets/images/svg/Icon_default_product.svg'
import logoPhotosiIT from './../../assets/images/svg/logo.svg'
import logoPhotosiDE from './../../assets/images/svg/logo_de.svg'
import logoPhotosiEN from './../../assets/images/svg/logo_en.svg'
import logoPhotosiES from './../../assets/images/svg/logo_es.svg'
import logoPhotosiFR from './../../assets/images/svg/logo_fr.svg'
import logoPhotosiNL from './../../assets/images/svg/logo_nl.svg'

const fallbackThumbnail = isIE() ? fallbackThumbnailIE11 : fallbackThumbnailSvg

export const CACHE_VERSION = process.env.REACT_APP_CACHE_VERSION
/**
 * APPLICATION ENVIRONMENT VARIABLE
 */

/**
 * The icons of the footer
 */
export const FOOTER_ICONS: IPictureObj = {
  numberOne: {
    alt: 'N°1 Icon',
    sources: [
      {
        dim: '(min-width: 1920px)',
        src: footer_100_1_xxl
      }
    ],
    src: footer_100_1_lg
  },
  footerShippingTypes: {
    alt: 'Shipping types Icon',
    sources: [
      {
        dim: '(min-width: 1920px)',
        src: footer_shipping_types
      }
    ],
    src: footer_shipping_types
  },
  madeInItaly: {
    alt: 'Italy Flag',
    sources: [
      {
        dim: '(min-width: 1920px)',
        src: footer_100_2_xxl
      }
    ],
    src: footer_100_2_lg
  },
  moneyBack: {
    alt: 'Smile Icon',
    sources: [
      {
        dim: '(min-width: 1920px)',
        src: footer_100_3_xxl
      }
    ],
    src: footer_100_3_lg
  },
  securePayment: {
    alt: 'Payments Logo',
    sources: [
      {
        dim: '(min-width: 1920px)',
        src: footer_pay_xxl
      },
      {
        dim: '(min-width: 1440px)',
        src: footer_pay_lg
      }
    ],
    src: footer_pay
  }
}

/**
 * The name of the cookie for the language
 */
export const LANGUAGE_COOKIE: string = 'phs_choosen_language'

export const TOKEN_COOKIE: string = 'userToken'
export const USERINFO_COOKIE: string = 'userInfo'

export const UNIQUE_RETAILER_COOKIE: string = 'UniqueRetailer'

export const DISTRIBUTOR_DATA_COOKIE: string = 'GDOInfoData'

/**
 * The logo link and description
 */
export const LOGO: IImage = {
  alt: 'PhotoSì Logo',
  src: {
    it: logoPhotosiIT,
    en: logoPhotosiEN,
    es: logoPhotosiES,
    de: logoPhotosiDE,
    nl: logoPhotosiNL,
    fr: logoPhotosiFR
  }
}

/**
 * The footer logo link and description
 */
export const FOOTER_LOGO: IImage = {
  alt: LOGO.alt,
  singleSrc: footerLogo
}

/**
 * The sentry dns
 */
export const SENTRY_DNS = process.env.REACT_APP_SENTRY

/**
 * GoogleTagManager ID
 */
export const GA_ID = `${process.env.REACT_APP_GA_ID}`
export const GTM_ID = `${process.env.REACT_APP_GTM_ID}`
export const DATALAYER_NAME = 'dataLayer'
export const DATALAYER_EVENT_CATEGORY = 'ShoppingCartConsumer'
export const GTM_GAEVENT = 'GAevent'

/**
 * Language
 */
export const DEFAULT_LANGUAGE = `${process.env.REACT_APP_DEFAULT_LANGUAGE}`
export const SUPPORTED_LANGUAGES = `${process.env.REACT_APP_SUPPORTED_LANGUAGES}`.split(',')
export const I18N_PATH = '/i18n'

/**
 * images folder
 */
export const IMAGES_PATH = '/images'

export const PATH_MAINSITE = isDev() ? '//www.test.photosi.com/' : '//www.photosi.com/'

export const FALLBACK_THUMBNAIL = fallbackThumbnail

export const GA_CID_COOKIE = '_ga'
