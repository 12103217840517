import { i18n } from 'i18next'
import { Observable } from 'rxjs'
import { isDev } from '../../app/helpers/utils'
import { StorageWrapperInterface } from '../../app/service/storage/StorageWrapper'
import {
  bodyParam,
  CustomRequestHeaderItemInterface,
  XHRRequestObservableInterface
} from '../../app/service/XHRRequest/XHRRequestObservable'
import { PaymentChoseedApiResultInterface } from '../adapter/PaymentChoosedAdapter'
import { SOFTWARECODE_KEY, SOFTWARE_CODE, SOFTWARE_VERSION, SOFTWARE_VERSION_KEY, X_API_KEY } from '../environment/const'
import OrderApiResult from '../model/api-result/OrderApiResult'
import { PaymentMethodApiResult } from '../model/api-result/PaymentMethodApiResultInterface'
import { ProductsHeaderApiResultInterface } from '../model/api-result/ProductsHeaderApiResultInterface'
import { ShippingAddressApiResultInterface } from '../model/api-result/ShippingAddressApiResult'
import { ShippingDateApiResultInterface } from '../model/api-result/ShippingDateApiResultInterface'
import { ShippingMethodApiResult } from '../model/api-result/ShippingMethodApiResult'
import { ShopManagerApiResultInterface } from '../model/api-result/ShopManagerApiResult'
import {
  OrderHeaderApiResultInterface,
  ShoppingCartHeaderApiResultInterface
} from '../model/api-result/ShoppingCartHeaderApiResultInterface'
import { ConsumerUserInfoApiResultInterface } from '../model/ConsumerUserInfoApiResultInterface'
import { ConsumerUserMarketingApiResultInterface } from '../model/ConsumerUserMarketingApiResultInterface'
import { ConsumerUserProfileApiResultInterface } from '../model/ConsumerUserProfileApiResultInterface'
import { CountryApiResult } from '../model/GeographicsInterface'
import { PatchHeaderParamsInterface } from '../model/PatchHeaderParamsInterface'
import { PatchRetailerParamsInterface } from '../model/PatchRetailerParamsInterface'
import { PaymentsMethodAcceptedTypes } from '../model/PaymentMethodAcceptedTypes'
import { PutConsumerUserParams } from '../model/PutConsumerUserParams'
import { UniqueRetailerDataInteface } from '../model/RetailerDataCookieInterface'
import { RetailerUserInfoApiResultInterface } from '../model/RetailerUserInfoApiResultInterface'
import { cartState } from '../model/ShoppingCartAcceptedStatus'
import { UserTypesApiResultInterface } from '../model/UserTypesApiResultInterface'
import { ProductsHeaderStoreInterface } from '../state-manager/ProductsHeaderStore'
import { ShoppingCartHeaderStoreInterface } from '../state-manager/ShoppingCartHeaderStore'
import { AccountsApiClient, AccountsApiClientInterface } from './FacadeApiClientSubsytem/AccountsApiClient'
import { CartConsumerHeaderApiClient, CartConsumerHeaderApiClientInterface } from './FacadeApiClientSubsytem/CartConsumerHeaderApiClient'
import {
  ConsumerShippingAddressesApiClient,
  ConsumerShippingAddressesApiClientInterface
} from './FacadeApiClientSubsytem/ConsumerShippingAddressesApiClient'
import { GeographicsApiClient, GeographicsApiClientInterface } from './FacadeApiClientSubsytem/GeographicsApiClient'
import { IpLabsShoppingCartApiClient, IpLabsShoppingCartApiClientInterface } from './FacadeApiClientSubsytem/IpLabsShoppingCartApiClient'
import {
  OrdersConsumerHeaderApiClient,
  OrdersConsumerHeaderApiClientInterface
} from './FacadeApiClientSubsytem/OrdersConsumerHeaderApiClient'
import {
  OrdersConsumerPaymentsApiClient,
  OrdersConsumerPaymentsApiClientInterface
} from './FacadeApiClientSubsytem/OrdersConsumerPaymentsApiClient'
import { PromotionsConsumerApiClient, PromotionsConsumerApiClientInterface } from './FacadeApiClientSubsytem/PromotionsConsumerApiClient'
import { ShopsManagerApiClient, ShopsManagerApiClientInterface } from './FacadeApiClientSubsytem/ShopsManagerApiClient'
import { FactorySCApiPayloadInterface } from './FactorySCApiPayload'

export type geographicsTypes = 'areas' | 'countries'

export interface ShopsManagerCoordsInterface {
  lat: number
  lng: number
  neLat: number
  neLng: number
  swLat: number
  swLng: number
}

export interface FacadeApiClientInterface {
  setGuid(guid: string): void
  getGuid(): string
  setDistributor(distributor: string): void
  getDistributor(): string
  setConfigurationCode(configurationCode: string): void
  getConfigurationCode(): string
  getProductsHeader$(): Observable<ProductsHeaderApiResultInterface>
  getHeader$(): Observable<ShoppingCartHeaderApiResultInterface>
  putHeader$(data: ShoppingCartHeaderStoreInterface): Observable<ShoppingCartHeaderApiResultInterface>
  putPaymentType$(data: string): Observable<ShoppingCartHeaderApiResultInterface>
  getShippingMethod$(): Observable<ShippingMethodApiResult[]>
  getShippingAddresses$(): Observable<ShippingAddressApiResultInterface[]>
  putShippingAddress$(id: string, body: bodyParam[]): Observable<ShippingMethodApiResult>
  patchShippingAddress$(id: string, body: bodyParam[]): Observable<ShippingMethodApiResult>
  postShippingAddress$(body: bodyParam[]): Observable<ShippingMethodApiResult>
  deleteShippingAddress$(id: string): Observable<ShippingMethodApiResult>
  getGeographics$(type: geographicsTypes, countryCode?: string | null): Observable<CountryApiResult[]>
  getShops$(coords: ShopsManagerCoordsInterface): Observable<ShopManagerApiResultInterface[]>
  getShopDetails$(shopCode: string): Observable<ShopManagerApiResultInterface[]>
  getPaymentMethods$(): Observable<PaymentMethodApiResult[]>
  deleteProductRow$(id: string): Observable<any>
  getProductRow$(id: string, retry?: boolean): Observable<any>
  getCouponCode$(campaignCode: string, userId: number): Observable<any>
  getShippingDate$(id: string): Observable<ShippingDateApiResultInterface[]>
  getConsumerUserInfo$(): Observable<ConsumerUserInfoApiResultInterface>
  getConsumerUserMarketingData$(): Observable<ConsumerUserMarketingApiResultInterface>
  getRetailerUserInfo$(): Observable<RetailerUserInfoApiResultInterface>
  getUserTypes$(): Observable<UserTypesApiResultInterface>
  patchHeaders$(data: PatchHeaderParamsInterface): Observable<ShoppingCartHeaderApiResultInterface>
  postOrderState$(): Observable<OrderHeaderApiResultInterface>
  patchPaymentType$(type: PaymentsMethodAcceptedTypes): Observable<PaymentChoseedApiResultInterface>
  putProductsHeader$(data: ProductsHeaderStoreInterface): Observable<ProductsHeaderApiResultInterface>
  getOrderState$(): Observable<OrderApiResult>
  setDistributor(distributor: string): void
  setConfigurationCode(configurationCode: string): void
  patchCartState$(state: cartState): Observable<ProductsHeaderApiResultInterface>
  cartValidation$(): Observable<null>
  patchUniqueRetailer$({ shopCode, siteDomain }: UniqueRetailerDataInteface): Observable<any>
  patchShopCode$(shopCode: string, retailerId: string, tax?: boolean): Observable<any>
  updateProductQty$(id: string, quantity: number): Observable<any>
  patchGaCid$(gaCid: string): Observable<any>
  patchShippingData$({ shopCode, lastName, firstName, phone, shippingType }: PatchRetailerParamsInterface): Observable<any>
  putConsumerUserProfile$(putConsumerUserParams: PutConsumerUserParams): Observable<ConsumerUserProfileApiResultInterface>
  patchBackToCart$(backToCart?: boolean): Observable<any>
  resetCustomRequestHeader(): void
}

/**
 * @desc configurable gruop of compatible subsystem, based on AbstractApiClient
 */
export class FacadeApiClient implements FacadeApiClientInterface {
  private guid: string
  private token: string
  private softwareCode: string = ''
  private softwareVersion: string = ''
  public distributor: string = ''
  public configurationCode: string = ''
  private language: string = ''

  constructor(
    i18n: i18n,
    public payload: FactorySCApiPayloadInterface,
    public xhrRequestObv: XHRRequestObservableInterface,
    private storage: StorageWrapperInterface
  ) {
    this.language = i18n.language
    this.guid = payload.getGuid() || ''
    this.token = payload.getToken() || ''
    this.resetCustomRequestHeader()
  }

  public resetCustomRequestHeader(): void {
    this.xhrRequestObv.clearCustomRequestHeader()

    this.softwareCode = this.storage.load(SOFTWARECODE_KEY) || SOFTWARE_CODE
    this.softwareVersion = this.storage.load(SOFTWARE_VERSION_KEY) || SOFTWARE_VERSION

    const xApiKey: string = X_API_KEY
    const xApiKeyHeader: CustomRequestHeaderItemInterface = { key: 'x-api-key', value: xApiKey }

    const bearer: CustomRequestHeaderItemInterface = { key: 'Authorization', value: `Bearer ${this.token}` }
    const acceptLanguage: CustomRequestHeaderItemInterface = { key: 'Accept-Language', value: `${this.language}` }

    const xSoftwareCode: CustomRequestHeaderItemInterface = { key: 'X-Software-Code', value: `${this.softwareCode}` }
    const xSoftwareVersion: CustomRequestHeaderItemInterface = { key: 'x-Software-Version', value: `${this.softwareVersion}` }

    if (isDev()) console.log(xSoftwareCode, xSoftwareVersion)

    this.xhrRequestObv.addCustomRequestHeaderItem(xApiKeyHeader)
    this.xhrRequestObv.addCustomRequestHeaderItem(bearer)
    this.xhrRequestObv.addCustomRequestHeaderItem(acceptLanguage)
    this.xhrRequestObv.addCustomRequestHeaderItem(xSoftwareCode)
    this.xhrRequestObv.addCustomRequestHeaderItem(xSoftwareVersion)
  }

  /*
   * FACADE - START SUBSYSTEMS
   */
  private subsystemCartConsumerHeaderApiClient(): CartConsumerHeaderApiClientInterface {
    return new CartConsumerHeaderApiClient({ ...this.getSubsystemArgs() })
  }

  private subsystemIpLabsShoppingCartApiClient(): IpLabsShoppingCartApiClientInterface {
    return new IpLabsShoppingCartApiClient({ ...this.getSubsystemArgs() })
  }

  private subsystemConsumerShippingAddressesApiClient(): ConsumerShippingAddressesApiClientInterface {
    return new ConsumerShippingAddressesApiClient({ ...this.getSubsystemArgs() })
  }

  private subsystemShopsManagerApiClient(): ShopsManagerApiClientInterface {
    return new ShopsManagerApiClient({ ...this.getSubsystemArgs() })
  }

  private subsystemOrderConsumerHeaderApiClient(): OrdersConsumerHeaderApiClientInterface {
    return new OrdersConsumerHeaderApiClient({ ...this.getSubsystemArgs() })
  }

  private subsystemOrdersConsumerPaymentsApiClient(): OrdersConsumerPaymentsApiClientInterface {
    return new OrdersConsumerPaymentsApiClient({ ...this.getSubsystemArgs() })
  }

  private subsystemPromotionsConsumerApiClient(): PromotionsConsumerApiClientInterface {
    return new PromotionsConsumerApiClient({ ...this.getSubsystemArgs() })
  }

  private subsystemAccountsApiClient(): AccountsApiClientInterface {
    return new AccountsApiClient({ ...this.getSubsystemArgs() })
  }

  private subsystemGeographicsApiClient(): GeographicsApiClientInterface {
    return new GeographicsApiClient({ ...this.getSubsystemArgs() })
  }
  /*
   * FACADE - END SUBSYSTEMS
   */

  /**
   * @desc generate params for compatibile subsystem
   */
  private getSubsystemArgs() {
    const softwareVersion = this.softwareVersion || SOFTWARE_VERSION
    const softwareCode = this.softwareCode || SOFTWARE_CODE

    return {
      xhrRequestObv: this.xhrRequestObv,
      guid: this.guid,
      querystring: `softwareCode=${softwareCode}&softwareVersion=${softwareVersion}&distributor=${this.distributor}`,
      configurationCode: this.configurationCode
    }
  }

  // PUBLIC SETTERS AND GETTERS OF FACADE CONFIGURATIONS

  setDistributor(distributor: string): void {
    this.distributor = distributor
  }

  getDistributor(): string {
    return this.distributor
  }

  setConfigurationCode(configurationCode: string) {
    this.configurationCode = configurationCode
  }

  getConfigurationCode(): string {
    return this.configurationCode
  }

  setGuid(guid: string): void {
    this.guid = guid
  }

  getGuid(): string {
    return this.guid
  }

  // PUBLIC SUBSYSTEM API CLIENT FUNCTIONS

  getHeader$(): Observable<ShoppingCartHeaderApiResultInterface> {
    return this.subsystemCartConsumerHeaderApiClient().getHeader$()
  }

  putPaymentType$(type: string): Observable<ShoppingCartHeaderApiResultInterface> {
    return this.subsystemCartConsumerHeaderApiClient().putPaymentType$(type)
  }

  putHeader$(data: ShoppingCartHeaderStoreInterface): Observable<ShoppingCartHeaderApiResultInterface> {
    return this.subsystemCartConsumerHeaderApiClient().putHeader$(data)
  }

  getShippingDate$(id: string): Observable<ShippingDateApiResultInterface[]> {
    return this.subsystemCartConsumerHeaderApiClient().getShippingDate$(id)
  }

  getShippingMethod$(): Observable<ShippingMethodApiResult[]> {
    return this.subsystemCartConsumerHeaderApiClient().getShippingMethod$()
  }

  getProductsHeader$(): Observable<ProductsHeaderApiResultInterface> {
    return this.subsystemIpLabsShoppingCartApiClient().getProductsHeader$()
  }

  putProductsHeader$(data: ProductsHeaderStoreInterface): Observable<ProductsHeaderApiResultInterface> {
    return this.subsystemIpLabsShoppingCartApiClient().putProductsHeader$(data)
  }

  patchCartState$(state: cartState): Observable<ProductsHeaderApiResultInterface> {
    return this.subsystemIpLabsShoppingCartApiClient().patchCartState$(state)
  }

  patchShopCode$(shopCode: string, retailerId: string, tax: boolean = false): Observable<ProductsHeaderApiResultInterface> {
    return this.subsystemIpLabsShoppingCartApiClient().patchShopCode$(shopCode, retailerId, tax)
  }

  patchUniqueRetailer$(args: UniqueRetailerDataInteface): Observable<any> {
    return this.subsystemIpLabsShoppingCartApiClient().patchUniqueRetailer$(args)
  }

  patchGaCid$(gaCid: string): Observable<any> {
    return this.subsystemIpLabsShoppingCartApiClient().patchGaCid$(gaCid)
  }

  patchShippingData$(params: PatchRetailerParamsInterface): Observable<any> {
    return this.subsystemIpLabsShoppingCartApiClient().patchShippingData$(params)
  }

  deleteProductRow$(id: string): Observable<any> {
    return this.subsystemIpLabsShoppingCartApiClient().deleteProductRow$(id)
  }

  getProductRow$(id: string, retry: boolean = false): Observable<any> {
    return this.subsystemIpLabsShoppingCartApiClient().getProductRow$(id, retry)
  }

  cartValidation$(): Observable<null> {
    return this.subsystemIpLabsShoppingCartApiClient().cartValidation$()
  }

  updateProductQty$(id: string, quantity: number): Observable<any> {
    return this.subsystemIpLabsShoppingCartApiClient().updateProductQty$(id, quantity)
  }

  getShippingAddresses$(): Observable<ShippingAddressApiResultInterface[]> {
    return this.subsystemConsumerShippingAddressesApiClient().getShippingAddresses$()
  }

  putShippingAddress$(id: string, body: bodyParam[]): Observable<ShippingMethodApiResult> {
    return this.subsystemConsumerShippingAddressesApiClient().putShippingAddress$(id, body)
  }

  patchShippingAddress$(id: string, body: bodyParam[]): Observable<ShippingMethodApiResult> {
    return this.subsystemConsumerShippingAddressesApiClient().patchShippingAddress$(id, body)
  }

  deleteShippingAddress$(id: string): Observable<ShippingMethodApiResult> {
    return this.subsystemConsumerShippingAddressesApiClient().deleteShippingAddress$(id)
  }

  postShippingAddress$(body: bodyParam[]): Observable<ShippingMethodApiResult> {
    return this.subsystemConsumerShippingAddressesApiClient().postShippingAddress$(body)
  }

  getShops$(coords: ShopsManagerCoordsInterface): Observable<ShopManagerApiResultInterface[]> {
    return this.subsystemShopsManagerApiClient().getShops$(coords)
  }

  getShopDetails$(shopCode: string): Observable<ShopManagerApiResultInterface[]> {
    return this.subsystemShopsManagerApiClient().getShopDetails$(shopCode)
  }

  patchHeaders$(data: PatchHeaderParamsInterface): Observable<ShoppingCartHeaderApiResultInterface> {
    return this.subsystemCartConsumerHeaderApiClient().patchHeaders$(data)
  }

  postOrderState$(): Observable<OrderHeaderApiResultInterface> {
    return this.subsystemOrderConsumerHeaderApiClient().postOrderState$()
  }

  patchPaymentType$(type: PaymentsMethodAcceptedTypes): Observable<PaymentChoseedApiResultInterface> {
    return this.subsystemOrderConsumerHeaderApiClient().patchPaymentType$(type)
  }

  getOrderState$(): Observable<OrderApiResult> {
    return this.subsystemOrderConsumerHeaderApiClient().getOrderState$()
  }

  patchBackToCart$(backToCart: boolean = true): Observable<any> {
    return this.subsystemOrderConsumerHeaderApiClient().patchBackToCart$(backToCart)
  }

  getPaymentMethods$(): Observable<PaymentMethodApiResult[]> {
    return this.subsystemOrdersConsumerPaymentsApiClient().getPaymentMethods$()
  }

  getCouponCode$(campaignCode: string, userId: number): Observable<any> {
    return this.subsystemPromotionsConsumerApiClient().getCouponCode$(campaignCode, userId)
  }

  getUserTypes$(): Observable<UserTypesApiResultInterface> {
    return this.subsystemAccountsApiClient().getUserTypesInfo$()
  }

  getConsumerUserInfo$(): Observable<ConsumerUserInfoApiResultInterface> {
    return this.subsystemAccountsApiClient().getConsumerUserInfo$()
  }

  getConsumerUserMarketingData$(): Observable<ConsumerUserMarketingApiResultInterface> {
    return this.subsystemAccountsApiClient().getConsumerUserMarketingData$()
  }

  getRetailerUserInfo$(): Observable<RetailerUserInfoApiResultInterface> {
    return this.subsystemAccountsApiClient().getRetailerUserInfo$()
  }

  putConsumerUserProfile$(params: PutConsumerUserParams): Observable<ConsumerUserProfileApiResultInterface> {
    return this.subsystemAccountsApiClient().putConsumerUserProfile$(params)
  }

  getGeographics$(type: geographicsTypes, countryCode: string | null): Observable<CountryApiResult[]> {
    return this.subsystemGeographicsApiClient().getGeographics$(type, countryCode)
  }
}
