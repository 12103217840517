import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

/**
 * @desc interface of single shipping address
 */
export interface ShippingAddressStoreInterface extends Instance<typeof ShippingAddressStore> {}
export interface ShippingAddressStoreInterfaceSnapshotIn extends SnapshotIn<typeof ShippingAddressStore> {}
export interface ShippingAddressStoreInterfaceSnapshotOut extends SnapshotOut<typeof ShippingAddressStore> {}

export const ShippingAddressStore = types
  .model('ShippingAddressStore', {
    additionalInfo: types.optional(types.maybeNull(types.string), null),
    area: types.optional(types.maybeNull(types.string), null),
    city: types.optional(types.maybeNull(types.string), null),
    country: types.optional(types.maybeNull(types.string), null),
    default: types.boolean,
    firstName: types.optional(types.maybeNull(types.string), null),
    id: types.string,
    isValid: types.boolean,
    lastName: types.optional(types.maybeNull(types.string), null),
    number: types.optional(types.maybeNull(types.string), null),
    phone: types.optional(types.maybeNull(types.string), null),
    street: types.optional(types.maybeNull(types.string), null),
    zipCode: types.optional(types.maybeNull(types.string), null),
    lat: types.optional(types.maybeNull(types.string), null),
    lng: types.optional(types.maybeNull(types.string), null)
  })
  .actions((self): any => ({
    setAddress(address: ShippingAddressStoreInterface) {
      self.additionalInfo = address.additionalInfo
      self.area = address.area
      self.city = address.city
      self.country = address.country
      self.default = address.default
      self.firstName = address.firstName
      self.id = address.id
      self.isValid = address.isValid
      self.lastName = address.lastName
      self.number = address.number
      self.phone = address.phone
      self.street = address.street
      self.zipCode = address.zipCode
      self.lat = address.lat
      self.lng = address.lng
    },
    setDefualt(defaultStatus: boolean) {
      self.default = defaultStatus
    },
    setFirstName(value: string) {
      self.firstName = value
    },
    setLastName(value: string) {
      self.lastName = value
    },
    setStreet(value: string) {
      self.street = value
    },
    setZipCode(value: string) {
      self.zipCode = value
    },
    setCity(value: string) {
      self.city = value
    },
    setArea(value: string) {
      self.area = value
    },
    setCountry(value: string) {
      self.country = value
    },
    setPhone(value: string) {
      self.phone = value
    },
    setNumber(value: string) {
      self.number = value
    },
    setAdditionInfo(value: string) {
      self.additionalInfo = value
    },
    setLatLng(lat: string, lng: string) {
      self.lat = lat
      self.lng = lng
    }
  }))
