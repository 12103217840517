import { RETAILER_USER } from '../environment/const'
import { RetailerUserInfoApiResultInterface } from '../model/RetailerUserInfoApiResultInterface'
import { RetailerUserInfoStoreInterfaceSnapshotOut } from '../state-manager/RetailerUserInfoStore'

export const RetailerUserInfoApiAdapter = (data: RetailerUserInfoApiResultInterface): RetailerUserInfoStoreInterfaceSnapshotOut => {
  return {
    authorizeBehaviorProfiling: data.authorizeBehaviorProfiling,
    authorizeMarketingCommunications: data.authorizeMarketingCommunications,
    companyName: data.companyName,
    email: data.email,
    extraData: data.extraData,
    fullName: data.fullName,
    gdprProcessorPartnerAcceptDate: data.gdprProcessorPartnerAcceptDate,
    gdprProcessorPhotosiAcceptDate: data.gdprProcessorPhotosiAcceptDate,
    hasPermissionBecomeRetailerUser: data.hasPermissionBecomeRetailerUser,
    id: data.id,
    iplabsRetailerId: data.iplabsRetailerId,
    isActive: data.isActive,
    isStaff: data.isStaff,
    isValidProfile: data.isValidProfile,
    name: data.name,
    permissions: data.permissions,
    phoneNumber: data.phoneNumber,
    shopCode: data.shopCode,
    showDeliveryNotes: data.showDeliveryNotes,
    showInvoices: data.showInvoices,
    surname: data.surname,
    taxcode: data.taxcode,
    types: [RETAILER_USER]
  }
}
