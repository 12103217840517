import amplitude from 'amplitude-js'
import { Observable, Observer, of } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { isDev } from '../helpers/utils'

const ampApykey = isDev() ? '49d1c97533059023ed2623c2edd77182' : '9c364a0ba1875bc7e772f55e8280f506'

export const amplitudeClient$ = (): Observable<amplitude.AmplitudeClient> => {
  return new Observable((observer: Observer<any>) => {
    const amplitudeClient = amplitude.getInstance()
    amplitudeClient.init(
      ampApykey,
      undefined,
      {
        saveEvents: true,
        includeUtm: true,
        includeReferrer: true
      },
      (amplitudeClient: amplitude.AmplitudeClient) => {
        amplitudeClient.setDomain(window.location.hostname)
        observer.next(amplitudeClient)
        observer.complete()
      }
    )

    setTimeout(() => {
      if (!observer.closed) {
        observer.error(new Error('Amplitude Timeout'))
      }
    }, 5000)
  }).pipe(
    catchError((err: Error) => {
      console.error('Failed to init amplitude', err)
      return of({
        setUserId(args) {
          console.log(args)
        },
        logEvent(args) {
          console.log(args)
        }
      } as amplitude.AmplitudeClient)
    })
  )
}

export default amplitudeClient$
