import { Observable } from 'rxjs'
import { bodyParam, XHROptions } from '../../../app/service/XHRRequest/XHRRequestObservable'
import ApiEnv from '../../environment/api-env'
import { ShippingDateApiResultInterface } from '../../model/api-result/ShippingDateApiResultInterface'
import { ShippingMethodApiResult } from '../../model/api-result/ShippingMethodApiResult'
import { ShoppingCartHeaderApiResultInterface } from '../../model/api-result/ShoppingCartHeaderApiResultInterface'
import { PatchHeaderParamsInterface } from '../../model/PatchHeaderParamsInterface'
import { ShoppingCartHeaderStoreInterface } from '../../state-manager/ShoppingCartHeaderStore'
import { AbstractApiClient } from '../AbstractApiClient'

export interface CartConsumerHeaderApiClientInterface {
  patchHeaders$(data: PatchHeaderParamsInterface): Observable<ShoppingCartHeaderApiResultInterface>
  getHeader$(): Observable<ShoppingCartHeaderApiResultInterface>
  putHeader$(data: ShoppingCartHeaderStoreInterface): Observable<ShoppingCartHeaderApiResultInterface>
  putPaymentType$(type: string): Observable<ShoppingCartHeaderApiResultInterface>
  getShippingDate$(id: string): Observable<ShippingDateApiResultInterface[]>
  getShippingMethod$(): Observable<ShippingMethodApiResult[]>
}

export class CartConsumerHeaderApiClient extends AbstractApiClient {
  private guid: string = ''
  constructor({ xhrRequestObv, querystring, guid }: any) {
    super(xhrRequestObv, querystring)
    this.guid = guid
  }

  /**
   *
   * @desc return an observable with xhrRequest with shopping cart header
   * @return {Observable}
   */
  getHeader$(): Observable<ShoppingCartHeaderApiResultInterface> {
    const params: XHROptions = {
      url: `${ApiEnv.headers.url}${this.guid}`
    }
    return this.get$(params)
  }

  /**
   * @desc update data of cart header
   * @return {Observable}
   */
  putHeader$(data: ShoppingCartHeaderStoreInterface): Observable<ShoppingCartHeaderApiResultInterface> {
    const bodyParams: bodyParam[] = this.getBodyParams(data)
    const params: XHROptions = {
      method: 'PUT',
      body: bodyParams,
      url: `${ApiEnv.headers.url}${this.guid}`
    }
    return this.get$(params)
  }

  /**
   * @desc put payment type
   * @param type put method of payment
   */
  putPaymentType$(type: string): Observable<ShoppingCartHeaderApiResultInterface> {
    const bodyParams = [{ key: 'paymentType', value: type }]
    const params: XHROptions = {
      method: 'PUT',
      body: bodyParams,
      url: `${ApiEnv.headers.url}${this.guid}`
    }
    return this.get$(params)
  }

  /**
   * @desc patch headers
   * @param data
   */
  patchHeaders$(data: PatchHeaderParamsInterface): Observable<ShoppingCartHeaderApiResultInterface> {
    const bodyParams: bodyParam[] = this.getBodyParams(data)

    const params: XHROptions = {
      method: 'PATCH',
      body: bodyParams,
      url: `${ApiEnv.headers.url}${this.guid}`
    }
    return this.get$(params)
  }

  /**
   * @desc get shipping date of default store
   * @param id
   */
  getShippingDate$(id: string): Observable<ShippingDateApiResultInterface[]> {
    const url = `${ApiEnv.shippingDate.url}`
    const params: XHROptions = {
      url: url.replace('{id}', this.guid)
    }
    return this.get$(params)
  }

  /**
   * @desc get list of supported shipping method of this cart
   * @return {Observable}
   */
  getShippingMethod$(): Observable<ShippingMethodApiResult[]> {
    const url = `${ApiEnv.shippingMethod.url}`
    const params: XHROptions = {
      url: url.replace('{id}', this.guid)
    }

    return this.get$(params)
  }
}
