import { OrderStoreInterfaceSnapshotOut } from '../state-manager/OrderStore'
export const OrderStoreMock: OrderStoreInterfaceSnapshotOut = {
  billingAddressArea: '',
  billingAddressCity: '',
  billingAddressCompanyName: '',
  billingAddressCountry: '',
  billingAddressFirstName: '',
  billingAddressId: '',
  billingAddressLastName: '',
  billingAddressNumber: '',
  billingAddressPhone: '',
  billingAddressStreet: '',
  billingAddressZipCode: '',
  confirmationDate: '',
  couponCodes: [],
  creationDate: '',
  currencyCode: '',
  deliveryDate: '',
  fixedCost: 0,
  formattedFixedCost: '',
  formattedPaymentCost: '',
  formattedPointsValue: '',
  formattedPrice: '',
  formattedProductVoucherValue: '',
  formattedShippingCost: '',
  formattedShippingVat: '',
  formattedSubTotal: '',
  formattedTotalDiscount: '',
  formattedTotalShipping: '',
  formattedTotalVoucher: '',
  formattedVat: '',
  formattedVoucherValue: '',
  formattedVoucherVat: '',
  guid: '',
  id: 0,
  note: '',
  number: 0,
  numberOfProducts: 0,
  orderState: '',
  paid: false,
  paymentCost: 0,
  paymentType: '',
  paymentUrl: '',
  pointsSpent: 0,
  pointsValue: 0,
  price: 0,
  productVoucherCode: '',
  productVoucherValue: 0,
  shippingAddressAdditionalInfo: '',
  shippingAddressArea: '',
  shippingAddressCity: '',
  shippingAddressCompanyName: '',
  shippingAddressCountry: '',
  shippingAddressEmail: '',
  shippingAddressFirstName: '',
  shippingAddressId: '',
  shippingAddressLastName: '',
  shippingAddressNumber: '',
  shippingAddressPhone: '',
  shippingAddressStreet: '',
  shippingAddressZipCode: '',
  shippingCost: 0,
  shippingType: '',
  shippingVat: 0,
  shopCode: '',
  subTotal: 0,
  totalDiscount: 0,
  totalShipping: 0,
  totalVoucher: 0,
  uploadState: '',
  userId: 0,
  vat: 0,
  version: '',
  voucherCode: '',
  voucherValue: 0,
  voucherVat: 0
}
