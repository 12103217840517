import { FacadeApiClientInterface } from '../api-service/FacadeApiClient'
import {
  acceptedShippingMethod,
  acceptedShippingMethodNames,
  SHIPPING_METHOD_EXPRESS,
  SHIPPING_METHOD_MAILORDER,
  SHIPPING_METHOD_PICKUP
} from '../environment/const'
import { ShippingMethodOutput } from '../model/ShippingMethodOutput'
import { ShoppingCartStoreInterface } from '../state-manager/ShoppingCartStore'
import MailOrderBackground from './../../assets/images/svg/delivery_shipping.svg'
import PickUpBackground from './../../assets/images/svg/delivery_store.svg'
import MailOrderIcon from './../../assets/images/svg/home.svg'
import ExpressOrderIcon from './../../assets/images/svg/icon_fast_delivery.svg'
import ExpressBackground from './../../assets/images/svg/image-express-shipping.svg'
import PickUpStoreIcon from './../../assets/images/svg/store.svg'

export const acceptedShippingMethodData: ShippingMethodOutput[] = [
  {
    code: SHIPPING_METHOD_PICKUP,
    image: PickUpBackground,
    formattedCost: 'cost_of_shipping',
    text: {
      title: 'pick_up_store_title',
      description: 'pick_up_store_description'
    },
    icon: PickUpStoreIcon
  },
  {
    code: SHIPPING_METHOD_MAILORDER,
    formattedCost: 'cost_of_shipping',
    image: MailOrderBackground,
    text: {
      title: 'mail_order_store_title',
      description: 'mail_order_store_description'
    },
    icon: MailOrderIcon
  },
  {
    code: SHIPPING_METHOD_EXPRESS,
    formattedCost: 'cost_of_shipping',
    image: ExpressBackground,
    text: {
      title: 'express_order_store_title',
      description: 'express_order_store_description'
    },
    icon: ExpressOrderIcon
  }
]

export interface ShippingMethodServiceInterface {
  isAccepted(methodCode: acceptedShippingMethodNames): boolean
  enrichMethod(methodCode: acceptedShippingMethodNames): ShippingMethodOutput | void
}

/**
 * @class
 * @desc get accepted shipping method with localization
 */
export class ShippingMethodService implements ShippingMethodServiceInterface {
  constructor(protected scApiClient: FacadeApiClientInterface, protected rootStore: ShoppingCartStoreInterface) {}

  isAccepted(methodCode: acceptedShippingMethodNames): boolean {
    return acceptedShippingMethod.includes(methodCode)
  }
  /**
   * @desc load method with translation
   * @param methodCode
   */
  enrichMethod(methodCode: acceptedShippingMethodNames): ShippingMethodOutput | void {
    const acceptedMethod = acceptedShippingMethodData.filter((method: ShippingMethodOutput) => {
      if (this.isAccepted(method.code) && method.code === methodCode) {
        return {
          code: method.code,
          image: method.image,
          formattedCost: method.formattedCost,
          text: {
            title: '',
            description: ''
          },
          icon: method.icon
        }
      } else {
        return false
      }
    })

    if (acceptedMethod && acceptedMethod.length > 0) {
      return acceptedMethod[0]
    }
  }
}
