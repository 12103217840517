import { Observable } from 'rxjs'
import { bodyParam, XHROptions } from '../../../app/service/XHRRequest/XHRRequestObservable'
import ApiEnv from '../../environment/api-env'
import { ProductsHeaderApiResultInterface } from '../../model/api-result/ProductsHeaderApiResultInterface'
import { PatchRetailerParamsInterface } from '../../model/PatchRetailerParamsInterface'
import { UniqueRetailerDataInteface } from '../../model/RetailerDataCookieInterface'
import { cartState } from '../../model/ShoppingCartAcceptedStatus'
import { ProductsHeaderStoreInterface } from '../../state-manager/ProductsHeaderStore'
import { AbstractApiClient } from '../AbstractApiClient'

export interface IpLabsShoppingCartApiClientInterface {
  getProductsHeader$(): Observable<ProductsHeaderApiResultInterface>
  putProductsHeader$(data: ProductsHeaderStoreInterface): Observable<ProductsHeaderApiResultInterface>
  patchCartState$(state: cartState): Observable<ProductsHeaderApiResultInterface>
  patchShopCode$(shopCode: string, retailerId: string, tax: boolean): Observable<ProductsHeaderApiResultInterface>
  patchUniqueRetailer$({ shopCode, siteDomain }: UniqueRetailerDataInteface): Observable<any>
  deleteProductRow$(id: string): Observable<any>
  getProductRow$(id: string, retry: boolean): Observable<any>
  cartValidation$(): Observable<null>
  updateProductQty$(id: string, quantity: number): Observable<any>
  patchGaCid$(gaCid: string): Observable<any>
  patchShippingData$({ shopCode, lastName, firstName, phone, shippingType }: PatchRetailerParamsInterface): Observable<any>
}

export class IpLabsShoppingCartApiClient extends AbstractApiClient {
  private guid: string = ''
  constructor({ xhrRequestObv, querystring, guid }: any) {
    super(xhrRequestObv, querystring)
    this.guid = guid
  }

  /**
   * @desc return an observable with xhrRequest with products header
   * @return {Observable}
   */
  getProductsHeader$(): Observable<ProductsHeaderApiResultInterface> {
    const params: XHROptions = {
      url: `${ApiEnv.productsHeader.url}${this.guid ? this.guid : 'current'}`
    }
    return this.get$(params)
  }

  /**
   * @desc update data of cart header
   * @return {Observable}
   */
  putProductsHeader$(data: ProductsHeaderStoreInterface): Observable<ProductsHeaderApiResultInterface> {
    const bodyParams: bodyParam[] = this.getBodyParams(data)

    const params: XHROptions = {
      method: 'PUT',
      body: bodyParams,
      url: `${ApiEnv.productsHeader.url}${this.guid}`
    }
    return this.get$(params)
  }

  /**
   * @desc set cart status
   * @param state
   */
  patchCartState$(state: cartState): Observable<ProductsHeaderApiResultInterface> {
    const bodyParams = [{ key: 'cartState', value: state }]
    const params: XHROptions = {
      method: 'PATCH',
      body: bodyParams,
      url: `${ApiEnv.productsHeader.url}${this.guid}`
    }
    return this.get$(params)
  }

  /**
   * @desc set shop code
   * @param state
   */
  patchShopCode$(shopCode: string, retailerId: string, tax: boolean = false): Observable<ProductsHeaderApiResultInterface> {
    const bodyParams = [
      { key: 'selectedShopCode', value: shopCode },
      { key: 'selectedRetailerId', value: retailerId },
      { key: 'requestReceivedTax', value: tax }
    ]
    const params: XHROptions = {
      method: 'PATCH',
      body: bodyParams,
      url: `${ApiEnv.productsHeader.url}${this.guid}`
    }
    return this.get$(params)
  }

  /**
   * @desc acceptd UniqueRetailerDataInteface (destructuring) with shopCode and siteDomain
   * @param param0
   */
  patchUniqueRetailer$({ shopCode, siteDomain }: UniqueRetailerDataInteface): Observable<any> {
    const bodyParams = [
      { key: 'shopCode', value: shopCode },
      { key: 'siteDomain', value: siteDomain }
    ]
    const params: XHROptions = {
      method: 'PATCH',
      body: bodyParams,
      url: `${ApiEnv.productsHeader.url}${this.guid}`
    }
    return this.get$(params)
  }

  /**
   * @desc remove single product row by id
   * @param id
   * @return {Observable}
   */
  deleteProductRow$(id: string): Observable<any> {
    const params: XHROptions = {
      method: 'DELETE',
      url: `${ApiEnv.productsHeaderRows.url}${id}`
    }
    return this.get$(params)
  }

  /**
   * @desc get single product row by id
   * @param id
   * @return {Observable}
   */
  getProductRow$(id: string, retry: boolean = false): Observable<any> {
    const params: XHROptions = {
      method: 'GET',
      url: `${ApiEnv.productsHeaderRows.url}${id}`
    }
    return this.get$(params, retry)
  }

  /**
   * @desc return cart validation status
   */
  cartValidation$(): Observable<null> {
    const url = `${ApiEnv.cartValidation.url}`
    const params: XHROptions = {
      method: 'GET',
      url: url.replace('{id}', this.guid)
    }
    return this.get$(params)
  }

  /**
   * @desc update quantity of products
   * @param id
   */
  updateProductQty$(id: string, quantity: number): Observable<any> {
    const params: XHROptions = {
      method: 'PUT',
      body: [
        { key: 'quantity', value: quantity },
        { key: 'id', value: id }
      ],
      url: `${ApiEnv.orderRows.url}${id}`
    }
    return this.get$(params)
  }

  /**
   * @desc send ga cid to backend
   * @param param0
   */
  patchGaCid$(gaCid: string): Observable<any> {
    const bodyParams = [{ key: 'gaCid', value: gaCid }]
    const params: XHROptions = {
      method: 'PATCH',
      body: bodyParams,
      url: `${ApiEnv.productsHeader.url}${this.guid}`
    }
    return this.get$(params)
  }

  patchShippingData$({ shopCode, lastName, firstName, phone, shippingType }: PatchRetailerParamsInterface): Observable<any> {
    const bodyParams = [
      { key: 'shopCode', value: shopCode },
      { key: 'firstName', value: firstName },
      { key: 'lastName', value: lastName },
      { key: 'phone', value: phone },
      { key: 'shippingType', value: shippingType }
    ]
    const params: XHROptions = {
      method: 'PATCH',
      body: bodyParams,
      url: `${ApiEnv.productsHeader.url}${this.guid}`
    }
    return this.get$(params)
  }
}
