import { addMiddleware, ISerializedActionCall, onAction, onPatch } from 'mobx-state-tree'
import { getCookie, setCookie } from '../../app/helpers/utils'
import { StorageWrapperInterface } from '../../app/service/storage/StorageWrapper'
import { LAST_PAYMENT_VENDOR_KEY } from '../environment/const'
import { ShoppingCartHeaderStoreInterfaceSnapshotOut } from '../state-manager/ShoppingCartHeaderStore'
import { ShoppingCartStoreInterface } from '../state-manager/ShoppingCartStore'
import { AmplitudeEventInterface } from './AmplitudeEvent'

export interface AmplitudeRootStoreMiddlewareInterface {
  middleware(): void
  listenAction(): void
  listenPatch(): void
}

export class AmplitudeRootStoreMiddleware implements AmplitudeRootStoreMiddlewareInterface {
  constructor(
    private rootStore: ShoppingCartStoreInterface,
    private amplitude: AmplitudeEventInterface,
    private storage: StorageWrapperInterface
  ) {}
  private getArgs(action: ISerializedActionCall): any {
    const args: Array<any> = Object.assign({}, action.args)
    return args[0] ? args[0] : {}
  }
  listenAction() {
    onAction(this.rootStore, (action: ISerializedActionCall) => {
      switch (action.name) {
        case 'setHeader':
          const header: ShoppingCartHeaderStoreInterfaceSnapshotOut = this.getArgs(action)
          if (header.couponCode) {
            this.amplitude.promoCodeSuccess(header.couponCode)
          }
          if (this.rootStore.header.couponCode.length > 0 && header.couponCode.length <= 0) {
            this.amplitude.promoCodeRemove(this.rootStore.header.couponCode)
          }
          break
        case 'setPaymentMethod':
          const paymentMethod = this.getArgs(action)
          this.storage.persist(LAST_PAYMENT_VENDOR_KEY, paymentMethod.code)
          break
        case 'setHeaderPickUpStore':
          break
        case 'setHeaderMailOrder':
          break
        case 'setStatus':
          const status = this.getArgs(action)
          switch (status) {
            case 'error':
              this.amplitude.cartAddError()
              break
          }
          break
        case 'getProductsQty':
          break
        case 'removeProduct':
          break
        case 'cartInteraction':
          break
        case 'cartHeaderStatus':
          break
        case 'thankYouPageInteraction':
          break
        case 'checkoutPaymentError':
          const argsPayment = this.getArgs(action)
          this.amplitude.paymentError(argsPayment)
          break
        case 'purchaseEvent':
          const cookieKey = `ShoppingCart_${action.name}_amplitude`
          if (getCookie(cookieKey) !== this.rootStore.header.id) {
            setCookie(cookieKey, this.rootStore.header.id, 3)
            this.amplitude.paymentSuccess()
            this.amplitude.userPurchase()
          }
          break
        case 'deniedCoupon':
          const args = this.getArgs(action)
          this.amplitude.promoCodeError(args)
          break
        case 'orderCompleted':
          break
        case 'gotoMapFromPayment':
          this.amplitude.gotoMapFromPayment()
          break
      }
    })
  }
  listenPatch() {
    onPatch(this.rootStore, (patch) => {
      if (patch.op === 'replace' && patch.path === '/queryStringParamsAccepted/productsAdded' && !!patch.value) {
        this.amplitude.cartAddSuccess()
      }

      if (patch.op === 'replace' && patch.path === '/progress') {
        switch (patch.value) {
          default:
            break
          case 1:
            this.amplitude.cartView()
            break
          case 2:
            this.amplitude.orderConfirmationStart()
            this.amplitude.deliverytypesView()
            break
          case 3:
            this.amplitude.deliverytypesChooseClick()
            this.amplitude.summaryView()
            break
          case 4:
            this.amplitude.paymentStart()
            // only on succesful payment => src/domain/components/payment/PaymentMethods.tsx
            break
        }
      }

      if (patch.op === 'replace' && patch.path === '/paymentMethod/code') {
        const paymentCode = patch.value
        this.amplitude.paymentSelect(paymentCode)
      }
    })
  }
  middleware() {
    addMiddleware(this.rootStore, (call: any, next: any) => {
      next(call, (value: any) => {
        return value
      })
    })
  }
}
