import { i18n } from 'i18next'
import { inject } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { GlobalsProvidedInterface } from '../../domain/model/CustomGlobalsProvided'
import { ProviderServiceInterface } from '../../domain/model/CustomServiceProvider'
import { SentryInterface } from '../../domain/model/SentryInterface'
import { isDev } from '../helpers/utils'
import { ErrorLevelInfo } from '../models/error/ErrorLevelInfo'
import { ErrorLevelWarning } from '../models/error/ErrorLevelWarning'
import errorIcon from './../../assets/images/svg/error_icon.svg'
import Error404 from './Error404'
import Picture from './Picture'
import { SpinnerComponentInterface } from './Spinner'
import { TransNS } from './TransNS'

interface ErrorComponentInterface {
  readonly i18n?: i18n
  readonly sentry?: SentryInterface
  globalSpinner$?: SpinnerComponentInterface
  i18nKeyMessage?: string
  message?: string
  children?: any
  onload?: any
  title?: string
  subTitle?: string
  code?: number
  buttonAction?: Function
  buttonLabel?: string
  retry?: boolean
  level?: 'error' | 'warning' | 'info'
}

interface ErrorMessageInterface extends ErrorComponentInterface {
  readonly i18n?: i18n
}

export const ErrorGeneric: React.FC<ErrorMessageInterface> = inject((states: ProviderServiceInterface & GlobalsProvidedInterface) => ({
  i18n: states.service.i18n,
  globalSpinner$: states.globals.globalSpinner,
  sentry: states.service.sentry
}))(
  ({
    i18n,
    children,
    message,
    onload,
    i18nKeyMessage,
    title,
    globalSpinner$,
    code,
    buttonAction,
    buttonLabel,
    retry,
    subTitle,
    sentry,
    level
  }) => {
    const [errorComponent, setErrorComponent] = useState(<></>)

    useEffect(() => {
      globalSpinner$ ? (globalSpinner$.visible = false) : null
      if (typeof onload === 'function') {
        onload()
      }

      switch (code) {
        case 404:
          setErrorComponent(<Error404 />)
          break
        default:
          setErrorComponent(
            <>
              {code && code === 404 && <Error404 />}
              <br />
              {i18n && i18nKeyMessage && i18n.getResource(i18n.language, 'error', i18nKeyMessage)}
              {((code && code >= 100000) || !code) && <Picture className={'error-image'} src={errorIcon} />}
              <br />
              <br />
              <div className={isDev() ? '' : 'hide'}>{!children && message}</div>
              {children}
              {subTitle}
              {i18nKeyMessage && !subTitle && <TransNS i18nKey={`error:${i18nKeyMessage}`}>Errore iniezione servizi</TransNS>}
            </>
          )
          break
      }

      if (message) {
        switch (level) {
          default:
          case 'error':
            console.error(message)
            break
          case 'warning':
            console.warn(message)
            break
          case 'info':
            console.log(message)
            break
        }
      }

      if (sentry) {
        let sentryError = new Error(message)

        switch (level) {
          default:
          case 'error':
            sentryError = new Error(message)
            break
          case 'warning':
            sentryError = new ErrorLevelWarning(message)
            break
          case 'info':
            sentryError = new ErrorLevelInfo(message)
            break
        }
        sentryError.name = code ? `Error ${code}` : `Error Generic`
        sentry.captureException(sentryError)
      }
    }, [])

    return (
      <div className="phs-consumer-cart phs-container tc">
        {!title && (
          <h1>
            <TransNS i18nKey="error:error">Errore</TransNS>
          </h1>
        )}
        <h3>{title}</h3>
        <div>{errorComponent}</div>
        <br />
        <br />
        {retry !== false && (
          <button className="phs-button secondary" onClick={() => (buttonAction ? buttonAction() : window.location.reload())}>
            {buttonLabel ? buttonLabel : <TransNS i18nKey="error:retry">Riprova</TransNS>}
          </button>
        )}
      </div>
    )
  }
)

export default ErrorGeneric
