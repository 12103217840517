import { replaceWithStringTheNullInObject } from '../helpers/utils'
import OrderApiResult from '../model/api-result/OrderApiResult'
import { OrderStoreInterfaceSnapshotOut } from '../state-manager/OrderStore'

/**
 * @desc adpter from cart header to cart header store
 * @param cartItems
 */
export const OrderStoreAdapter = (order: OrderApiResult): OrderStoreInterfaceSnapshotOut => {
  const orderNormalized = replaceWithStringTheNullInObject(order)

  return {
    billingAddressArea: orderNormalized.billingAddressArea,
    billingAddressCity: orderNormalized.billingAddressCity,
    billingAddressCompanyName: orderNormalized.billingAddressCompanyName,
    billingAddressCountry: orderNormalized.billingAddressCountry,
    billingAddressFirstName: orderNormalized.billingAddressFirstName,
    billingAddressId: orderNormalized.billingAddressId,
    billingAddressLastName: orderNormalized.billingAddressLastName,
    billingAddressNumber: orderNormalized.billingAddressNumber,
    billingAddressPhone: orderNormalized.billingAddressPhone,
    billingAddressStreet: orderNormalized.billingAddressStreet,
    billingAddressZipCode: orderNormalized.billingAddressZipCode,
    confirmationDate: orderNormalized.confirmationDate,
    couponCodes: orderNormalized.couponCodes,
    creationDate: orderNormalized.creationDate,
    currencyCode: orderNormalized.currencyCode,
    deliveryDate: orderNormalized.deliveryDate,
    fixedCost: orderNormalized.fixedCost,
    formattedFixedCost: orderNormalized.formattedFixedCost,
    formattedPaymentCost: orderNormalized.formattedPaymentCost,
    formattedPointsValue: orderNormalized.formattedPointsValue,
    formattedPrice: orderNormalized.formattedPrice,
    formattedProductVoucherValue: orderNormalized.formattedProductVoucherValue,
    formattedShippingCost: orderNormalized.formattedShippingCost,
    formattedShippingVat: orderNormalized.formattedShippingVat,
    formattedSubTotal: orderNormalized.formattedSubTotal,
    formattedTotalDiscount: orderNormalized.formattedTotalDiscount,
    formattedTotalShipping: orderNormalized.formattedTotalShipping,
    formattedTotalVoucher: orderNormalized.formattedTotalVoucher,
    formattedVat: orderNormalized.formattedVat,
    formattedVoucherValue: orderNormalized.formattedVoucherValue,
    formattedVoucherVat: orderNormalized.formattedVoucherVat,
    guid: orderNormalized.guid,
    id: orderNormalized.id,
    note: orderNormalized.note,
    number: orderNormalized.number,
    numberOfProducts: orderNormalized.numberOfProducts,
    orderState: orderNormalized.orderState,
    paid: orderNormalized.paid,
    paymentCost: orderNormalized.paymentCost,
    paymentType: orderNormalized.paymentType,
    paymentUrl: orderNormalized.paymentUrl,
    pointsSpent: orderNormalized.pointsSpent,
    pointsValue: orderNormalized.pointsValue,
    price: orderNormalized.price,
    productVoucherCode: orderNormalized.productVoucherCode,
    productVoucherValue: orderNormalized.productVoucherValue,
    shippingAddressAdditionalInfo: orderNormalized.shippingAddressAdditionalInfo,
    shippingAddressArea: orderNormalized.shippingAddressArea,
    shippingAddressCity: orderNormalized.shippingAddressCity,
    shippingAddressCompanyName: orderNormalized.shippingAddressCompanyName,
    shippingAddressCountry: orderNormalized.shippingAddressCountry,
    shippingAddressEmail: orderNormalized.shippingAddressEmail,
    shippingAddressFirstName: orderNormalized.shippingAddressFirstName,
    shippingAddressId: orderNormalized.shippingAddressId,
    shippingAddressLastName: orderNormalized.shippingAddressLastName,
    shippingAddressNumber: orderNormalized.shippingAddressNumber,
    shippingAddressPhone: orderNormalized.shippingAddressPhone,
    shippingAddressStreet: orderNormalized.shippingAddressStreet,
    shippingAddressZipCode: orderNormalized.shippingAddressZipCode,
    shippingCost: orderNormalized.shippingCost,
    shippingType: orderNormalized.shippingType,
    shippingVat: orderNormalized.shippingVat,
    shopCode: orderNormalized.shopCode,
    subTotal: orderNormalized.subTotal,
    totalDiscount: orderNormalized.totalDiscount,
    totalShipping: orderNormalized.totalShipping,
    totalVoucher: orderNormalized.totalVoucher,
    uploadState: orderNormalized.uploadState,
    userId: orderNormalized.userId,
    vat: orderNormalized.vat,
    version: orderNormalized.version,
    voucherCode: orderNormalized.voucherCode,
    voucherValue: orderNormalized.voucherValue,
    voucherVat: orderNormalized.voucherVat
  }
}
