import { Observable } from 'rxjs'
import { XHROptions } from '../../../app/service/XHRRequest/XHRRequestObservable'
import ApiEnv from '../../environment/api-env'
import { PaymentMethodApiResult } from '../../model/api-result/PaymentMethodApiResultInterface'
import { AbstractApiClient } from '../AbstractApiClient'

export interface OrdersConsumerPaymentsApiClientInterface {
  getPaymentMethods$(): Observable<PaymentMethodApiResult[]>
}

export class OrdersConsumerPaymentsApiClient extends AbstractApiClient {
  constructor({ xhrRequestObv, querystring, guid }: any) {
    super(xhrRequestObv, querystring)
  }

  /**
   * @desc get payments method allowed
   * @return {Observable}
   */
  getPaymentMethods$(): Observable<PaymentMethodApiResult[]> {
    const params: XHROptions = {
      url: `${ApiEnv.paymentsMethod.url}`
    }
    return this.get$(params)
  }
}
